import React, { useState, useRef } from "react";
import Select from "components/Common/Select/Select";
import { KeysNav, InnerNav } from "./Keys.styles";
import Panel from "components/Common/Panel/Panel";
import { GetListTable } from "components/Context/getListTable/getListTable";

import { GetUnitsDropDown } from "components/Context/getUnitsDropDown/getUnitsDropDown";
import { GetBuildingsDropDown } from "components/Context/getBuildingDropDown/getBuildingDropDown";

import { getKeyRows, getKeyHeader } from "hooks/Key/keysTableFunctions";

const minimumRooms = 3;

const Rooms = () => {
  const [search, setSearch] = useState("");
  const [building, setBuilding] = useState();
  const [department, setDepartment] = useState();
  const [room, setRoom] = useState("");
  const [count, setCount] = useState({
    rooms: 0,
    buildings: 0,
  });
  const [searchResults, setSearchResults] = useState(0);
  const [buildings, setBuildings] = useState([]);
  const saveBuilding = useRef("");
  const saveDepartment = useRef("");
  const offset = useRef(0);
  const [unitByProxy, setUnitByProxy] = useState(false);

  const handleCheckbox1Change = () => {
    setUnitByProxy(!unitByProxy);

    setDepartment(saveDepartment.current);
  };

  const handleSelectDepartment = (e) => {
    if (e === "") {
      clearCount();
      setDepartment();
      setBuildings([]);
      if (room.length < minimumRooms) {
        setRoom("");
      }
    } else setDepartment(e);

    saveDepartment.current = e;
  };

  const handleSetRoom = (e) => {
    const value = e.target.value;

    if (
      value.length >= minimumRooms ||
      building !== undefined ||
      department !== undefined
    ) {
      setRoom(value);
    } else if (room != "") {
      setRoom("");
    }
  };

  const handleSelectBuilding = (e) => {
    setBuilding(e);
    saveBuilding.current = e;
  };

  const handleSelectAllBuilding = (e) => {
    setBuilding(e);
    setDepartment();
    saveBuilding.current = e;
    if (e === "") {
      clearCount();
    }
  };

  function updateCount(offsets) {
    offset.current = offsets;
  }

  function clearCount() {
    setCount({
      rooms: 0,
      buildings: 0,
    });
  }

  function filterKeys(data) {
    let filtered = 0;

    let buildings = data
      .filter(({ bldg, bldgName }) => {
        const result = bldgName !== null && bldg !== null;

        if (!result) filtered++;
        return result;
      })
      .map(({ bldg, bldgName }) => ({
        id: bldg,
        name: toTitleCase(bldgName) + " (" + bldg + ")",
      }))
      .sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

    let buildingList = [
      ...new Map(buildings.map((item) => [item["id"], item])).values(),
    ];

    // if the chosen building exists in the new list,
    //  keep it, otherwise blank out the list
    //  this removes the problem where having chosen value not
    //  exist in the department causes all data to not match

    if (
      saveBuilding.current !== "" &&
      buildingList.some((item) => item.id.includes(building))
    ) {
      setBuilding(saveBuilding.current);
    } else {
      setBuilding();
    }

    if (buildingList.length == 0) setDepartment();

    setBuildings(buildingList);

    setCount({
      rooms: buildings.length + filtered,
      buildings: buildingList.length,
    });
  }

  const updateSearchCount = (srch) => {
    if (srch === 0) clearCount(); // reset counts when there is a zero count

    setSearchResults(srch);
  };

  return (
    <div>
      <KeysNav>
        <InnerNav>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <label>
              <b>Include Unit By Proxy:</b>
            </label>
            <input
              type="checkbox"
              checked={unitByProxy}
              onChange={handleCheckbox1Change}
            />
          </div>
          <GetUnitsDropDown
            onSelectItem={handleSelectDepartment}
            keysFilter={true}
            building={building}
            room={room}
            unitId={unitByProxy ? "$DEFIN" : null}
          />
          {(department !== undefined && department !== "") ||
          (room?.length ?? 0) >= minimumRooms ? (
            <Select
              label="Building"
              optionsheader="-- Select a Building --"
              options={buildings}
              onChange={(e) => handleSelectBuilding(e)}
              defaultValue={saveBuilding.current}
            />
          ) : (
            <GetBuildingsDropDown
              onSelectItem={(e) => handleSelectAllBuilding(e)}
              defaultValue={saveBuilding.current}
              keysFilter={true}
              room={room}
              unitId={unitByProxy ? "$DEFIN" : null}
            />
          )}
          <div>
            <label>Room</label>
            <br />
            <input
              id="room"
              label="Room"
              type="text"
              className="form-control"
              placeholder="Search..."
              style={{
                height: "2.3rem",
                border: "1px solid rgb(179, 179, 179)",
              }}
              //value={room.current}
              onChange={(e) => handleSetRoom(e)}
            />
          </div>
          &nbsp;
          <div>
            <label>Search</label>
            <br />
            <input
              id="search"
              label="Search"
              type="text"
              className="form-control"
              placeholder="Search..."
              style={{
                height: "2.3rem",
                border: "1px solid rgb(179, 179, 179)",
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </InnerNav>
        <InnerNav>
          <Panel label="Keys" data={count.rooms - offset.current} headers="" />

          <Panel label="Buildings" data={count.buildings} headers="" />

          <Panel label="Results" data={searchResults} headers="" />
        </InnerNav>
      </KeysNav>

      <div>
        <GetListTable
          department={department}
          room={room}
          building={building}
          search={search}
          unitId={unitByProxy ? "$DEFIN" : null}
          updateCount={updateCount}
          updateBuildings={(data) => filterKeys(data)}
          updateSearchCount={(srch) => updateSearchCount(srch)}
          getHeader={getKeyHeader}
          getRows={getKeyRows}
          keysFilterDatabase={true}
          formatType="key"
        />
      </div>
    </div>
  );

  function toTitleCase(str) {
    if (str === null) {
      return null;
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
};
export default Rooms;
